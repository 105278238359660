import type React from 'react';
import type { SearchClient } from 'algoliasearch';
import type { Tracker } from '@yoursurprise/segment-analytics';
import type { InstantSearchServerState } from 'react-instantsearch-core';
import type { UiState } from 'instantsearch.js';
import type { Components } from '../../../general/Components/ComponentTypes';
import { IMAGE_TEXT_TYPE, BUTTON_TYPE, BANNER_TYPE, BOXES_TYPE, BREADCRUMBS_TYPE, CATEGORIES_TYPE, DESCRIPTION_TYPE, HEADER_TYPE, HEADING_TYPE,
    IMAGE_LAYOUT_TYPE, INSPIRATION_BOX_TYPE, NEWSLETTER_TYPE, PHOTOSLURP_TYPE, PRODUCT_SLIDER_TYPE, PRODUCTS_TYPE,
    PROMOTION_TYPE, SEO_TEXT_TYPE, TEXT_TYPE, USPS_TYPE } from '../../../general/Components/ComponentTypes';
import Header from '../../../general/Components/Header/Header';
import Categories from '../../../general/Components/Categories/Categories';
import ProductCategoryPage from './ProductCategoryPage';
import SeoText from './Text/SeoText';
import Text from './Text/Text';
import Photoslurp from '../../../general/Components/Photoslurp/Photoslurp';
import ProductSlider from './ProductSlider/ProductSlider';
import InstantSearchWrapper from '../../general/InstantSearch/InstantSearchWrapper';
import Description from '../../../general/Components/Description/Description';
import Usps from '../../../general/Components/Usps/Usps';
import Promotion from '../../../general/Components/Promotion/Promotion';
import Boxes from '../../../general/Components/Box/Boxes';
import Heading from '../../../general/Components/Heading/Heading';
import ImageLayout from '../../../general/Components/Image/ImageLayout';
import BreadcrumbsWrapper from '../../../general/Components/Breadcrumbs/BreadcrumbsWrapper';
import Newsletter from '../../../general/Components/Newsletter/Newsletter';
import Banner from '../../../general/Components/Banner/Banner';
import Button from '../../../general/Components/Button/Button';
import InspirationBox from '../../../general/Components/InspirationBox/InspirationBox';
import ImageText from '../../../general/Components/Image/ImageText';

type ComponentRendererProps = {
    algoliaServerState?: InstantSearchServerState;
    components: Components;
    currency: string;
    initialUiState?: UiState;
    locale: string;
    pageId: number;
    pageTypeSlug: string;
    photoGiftUrl: string;
    productIndexName: string;
    replicaIndexBase: string;
    resultsState?: unknown;
    searchClient: SearchClient;
    tracker?: Tracker | undefined;
};

const ComponentRenderer: React.FC<ComponentRendererProps> = ({
    algoliaServerState,
    components,
    currency,
    initialUiState,
    locale,
    pageId,
    pageTypeSlug,
    photoGiftUrl,
    productIndexName,
    replicaIndexBase,
    searchClient,
    tracker,
}) => (
    <InstantSearchWrapper
        uiState={initialUiState}
        productIndexName={productIndexName}
        algoliaServerState={algoliaServerState}
        searchClient={searchClient}
        currency={currency}
        replicaIndexBase={replicaIndexBase}
        tracker={tracker}
    >
        {components.map((component) => {
            if (component.type === BANNER_TYPE) {
                return (
                    <Banner key={component.id} component={component}/>
                );
            }

            if (component.type === BUTTON_TYPE) {
                return <Button key={component.id} component={component}/>;
            }

            if (component.type === HEADER_TYPE) {
                return (
                    <Header
                        key={component.id}
                        image={component.image}
                        isSmartImage={component.isSmartImage}
                        title={component.title}
                        alt={component.alt}
                        breadcrumbs={component.breadcrumbs}
                    />
                );
            }

            if (component.type === INSPIRATION_BOX_TYPE) {
                return <InspirationBox key={component.id} component={component}/>;
            }

            if (component.type === IMAGE_TEXT_TYPE) {
                return <ImageText key={component.id} component={component}/>;
            }

            if (component.type === DESCRIPTION_TYPE) {
                return <Description key={component.id} text={component.text} />;
            }

            if (component.type === CATEGORIES_TYPE) {
                return <Categories key={component.id} categories={component.links} withImages={component.withImages} />;
            }

            if (component.type === PRODUCTS_TYPE) {
                return (
                    <ProductCategoryPage
                        key={component.id}
                        currency={currency}
                        distinct={component.distinct}
                        hasFilter={component.hasFilter}
                        limit={component.limit}
                        pageTypeSlug={pageTypeSlug}
                        pageId={pageId}
                        setup={component.setup}
                        photoGiftUrl={photoGiftUrl}
                        productIndexName={productIndexName}
                        replicaIndexBase={replicaIndexBase}
                        imageOverrides={component.imageOverrides}
                        tracker={tracker}
                    />
                );
            }

            if (component.type === PHOTOSLURP_TYPE) {
                return <Photoslurp key={component.id} locale={locale} photoslurpId={component.photoslurpId} />;
            }

            if (component.type === PRODUCT_SLIDER_TYPE) {
                return (
                    <ProductSlider
                        pageTypeSlug={pageTypeSlug}
                        key={component.id}
                        component={component}
                        currency={currency}
                        productIndexName={productIndexName}
                        replicaIndexBase={replicaIndexBase}
                        title={component.title}
                        tracker={tracker}
                    />
                );
            }

            if (component.type === USPS_TYPE) {
                return <Usps key={component.id} component={component} editable={false} title={component.title} />;
            }

            if (component.type === PROMOTION_TYPE) {
                return <Promotion component={component} editable={false} key={component.id} />;
            }

            if (component.type === BOXES_TYPE) {
                return <Boxes
                    asSlider={component.asSlider}
                    asSliderMobile={component.asSliderMobile}
                    editable={false} key={component.id}
                    items={component.boxItems}
                    isLocked={false}
                    boxType={component.boxType}
                    title={component.title}
                    limitMobile={component.limitMobile}
                />;
            }

            if (component.type === IMAGE_LAYOUT_TYPE) {
                return <ImageLayout key={component.id} images={component.images} setupId={component.setupId} isEditable={false} />;
            }

            if (component.type === HEADING_TYPE) {
                return <Heading alignment={component.alignment} hideOnMobile={component.hideOnMobile} key={component.id} isH1={component.isH1} text={component.text} title={component.title} />;
            }

            if (component.type === SEO_TEXT_TYPE) {
                return <SeoText key={component.id} text={component.text}/>;
            }

            if (component.type === TEXT_TYPE) {
                return <Text key={component.id} text={component.text}/>;
            }

            if (component.type === BREADCRUMBS_TYPE) {
                return (<BreadcrumbsWrapper key={component.id} breadcrumbs={component.breadcrumbs} />);
            }

            if (component.type === NEWSLETTER_TYPE) {
                return (<Newsletter key={component.id} component={component} isEditable={false} />);
            }

            return null;
        })}
    </InstantSearchWrapper>
);

export default ComponentRenderer;
